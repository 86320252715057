import React, { useState } from 'react';
import { Text, StyleSheet, View, Image, TouchableOpacity, TextInput, KeyboardAvoidingView ,ScrollView} from "react-native";
import { Border, FontSize, FontFamily, Color, Padding, LocalizedString } from "@common";
import { Popover, Button, Box, FlatList, Center, NativeBaseProvider } from "native-base";
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import RouteItem from "./RouteItem";
import CalendarView from "./CalendarView";
import DestinationDropdown from "./DestinationDropdown";
import { createAction } from '@utils';
import { DUMMY, DEFAULT_FROM_DESTINATION } from "@constants"

const DesktopSearchBar = ({
    dispatch,
    destinationRoutes,
    routes,
    toSelectedStation,
    fromSelectedStation,
    selectedDate,
    searchButtonPressed,
    calendarDateSelected,
    showBanner,
    onToItemSelected
}) => { 
    
    const [isShowingToPopOver, setIsShowingToPopOver] = useState(false);
    const [isShowingFromPopOver, setIsShowingFromPopOver] = useState(false);
    const [isShowingCalendarPopOver, setIsShowingCalendarPopOver] = useState(false);
    const language = useSelector((state) => state.config.language);
    
    const onFromItemPress = (item) => {
        dispatch(createAction('trip/setFromSelectedStation')(item));
        //console.log(`item chosen ${JSON.stringify(item)}`);
        setIsShowingFromPopOver(false);
    }
    
    const calendarPressed = (selectedDate) => {
        setIsShowingCalendarPopOver(false)
        if (calendarDateSelected && typeof calendarDateSelected === 'function') {
            const dt = DateTime.fromFormat(selectedDate, 'yyyy-MM-dd');
            calendarDateSelected(dt,selectedDate);
          }
    }
    
    const onToItemPress = (item) => {
        dispatch(createAction('trip/setToSelectedStation')(item));
        setIsShowingToPopOver(false);
        onToItemSelected && onToItemSelected(item)
        
    }
    
    const switchDirectionPressed = () => {
        dispatch(createAction('trip/setToSelectedStation')(fromSelectedStation));
        dispatch(createAction('trip/setFromSelectedStation')(toSelectedStation));    
    }
   


    const renderFromRouteItem = ({ item }) => (
        <RouteItem item={item} onItemPress={onFromItemPress} />
        )

        
    const renderToRouteItem = ({ item }) => (
        <RouteItem item={item} onItemPress={onToItemPress} />
    )
        
    let searchButtonStyle = toSelectedStation!=null && fromSelectedStation!=null && selectedDate!=null ? [styles.buttonSearch, styles.buttonSearchBg] : [styles.buttonSearch, styles.buttonSearchBg, styles.disabled];
    
    if (DUMMY){

        return (      
            <View style={styles.horizontal1}>                                
                    <Text style={styles.titleText}>{LocalizedString.onlineTicketPurchasing}</Text>                
            </View>            
        )

    }else {
        return (
        <View>
            {showBanner ?  
              <View style={styles.horizontal1}>
              <Text style={styles.titleText}>KAMPAR PUTRA SENTRAL</Text>     
              <Text style={styles.subtitleText}>{LocalizedString.placeForEveryone}</Text>    
              </View>
            : undefined}
            
            {showBanner ? <View style={styles.gap}></View> : undefined}
            
            <View style={styles.horizontal}>
      
            <View style={styles.containerTo}>
            <View style={[styles.fromFrame, styles.detailsSpaceBlock]}>
            <View style={styles.fromWrapper}>
            <Text style={[styles.from, styles.fromTypo]}>{LocalizedString.from}</Text>
            </View>
            </View>
            <Popover trigger={triggerProps => {
                return <Button {...triggerProps} 
                style={[styles.containerInputFrom, styles.detailsWrapperBorder]}    
                >
                <View style={[styles.details, styles.detailsSpaceBlock]}>
                <Image
                style={styles.at21}
                resizeMode="cover"
                source={require("../assets/20230728-at-2-1.png")}
                />
                <View style={[styles.kamparWrapper, styles.wrapperSpaceBlock]}>
                <Text style={[styles.kampar, styles.home1Typo]}>{fromSelectedStation != null ? fromSelectedStation.stationName : "" }</Text>
                </View>
                </View>
                <View style={styles.larWrapperSpaceBlock}>
                <Text style={styles.kampar1}>{fromSelectedStation != null ? fromSelectedStation.stationCode : "" }</Text>
                </View>
                </Button>
            }}
            isOpen={isShowingFromPopOver} 
            onClose={() => setIsShowingFromPopOver(!isShowingFromPopOver)}
            >
            <Popover.Content accessibilityLabel={LocalizedString.chooseFrom} w="300">
            <Popover.Arrow />
            <Popover.CloseButton onPress={() => setIsShowingFromPopOver(false)}  />
            <Popover.Header>{LocalizedString.chooseFrom}</Popover.Header>
            <Popover.Body>
            <FlatList
            data={[DEFAULT_FROM_DESTINATION]}
            renderItem={renderFromRouteItem}
            keyExtractor={(item, index) => index.toString()}
            contentContainerStyle={styles.bookingsList}                     
            onEndReachedThreshold={0.1} // This will trigger handleLoadMore when 10% of the bottom is reached.
            />
            </Popover.Body>
            </Popover.Content>
            </Popover>
            
            </View>
            <View style={styles.ar21Container}>
                <Image
                    resizeMode="cover"
                    style={styles.ar21}
                    source={require("../assets/arrow-right-long-solid.png")}
                />
            </View>
            <View style={styles.containerTo}>
            <View style={[styles.fromFrame, styles.detailsSpaceBlock]}>
            <View style={styles.fromWrapper}>
            <Text style={[styles.from, styles.fromTypo]}>{LocalizedString.to}</Text>
            </View>
            </View>
            <Popover trigger={triggerProps => {
                return <Button {...triggerProps}
                style={[styles.containerInputFrom, styles.detailsWrapperBorder]}   
                onPress={() => setIsShowingToPopOver(!isShowingToPopOver)}
                useRNModal={true}           
                >
                <View style={[styles.details1, styles.detailsSpaceBlock]}>
                <Image
                style={styles.at22}
                resizeMode="cover"
                source={require("../assets/20230728-at-2-2.png")}
                />
                <View style={[styles.kamparWrapper, styles.wrapperSpaceBlock]}>
                <Text style={[styles.kampar, styles.home1Typo]}>
                {toSelectedStation != null ? toSelectedStation.stationName : "" }
                </Text>
                </View>
                </View>
                <View style={[styles.larWrapper, styles.larWrapperSpaceBlock]}>
                <Text style={styles.kampar1}>{toSelectedStation != null ? toSelectedStation.stationCode : "" }</Text>
                </View>
                </Button>
            }}
            isOpen={isShowingToPopOver} 
            onClose={() => setIsShowingToPopOver(!isShowingToPopOver)}
            useRNModal={true}
            >
            <Popover.Content accessibilityLabel={LocalizedString.chooseTo} w="300">
            <Popover.Arrow />
            <Popover.CloseButton  onPress={() => setIsShowingToPopOver(false)} />
            <Popover.Header>{LocalizedString.chooseFrom}</Popover.Header>
            <Popover.Body style={styles.searchBarPopover}>
                <DestinationDropdown
                    dispatch={dispatch}
                    destinationRoutes={destinationRoutes}
                    onToItemPress={onToItemPress}
                 />
            {/* <FlatList
            data={destinationRoutes}
            renderItem={renderToRouteItem}
            keyExtractor={(item, index) => index.toString()}
            contentContainerStyle={styles.bookingsList}                     
            onEndReachedThreshold={0.1} // This will trigger handleLoadMore when 10% of the bottom is reached.
            /> */}
            </Popover.Body>
            </Popover.Content>
            </Popover>
            
            </View>
            <View style={styles.containerTo}>
            <View style={[styles.fromFrame, styles.detailsSpaceBlock]}>
            <View style={styles.fromWrapper}>
            <Text style={[styles.from, styles.fromTypo]}>{LocalizedString.departure}</Text>
            </View>
            </View>
            <Popover trigger={triggerProps => {
                return <Button {...triggerProps} style={[styles.containerInputFrom, styles.detailsWrapperBorder]}
                onPress={()=> setIsShowingCalendarPopOver(!isShowingCalendarPopOver)}
                useRNModal={true}
                >
                <View style={[styles.details, styles.detailsSpaceBlock]}>
                <Image
                style={styles.at221}
                resizeMode="cover"
                source={require("../assets/calender1.png")}
                />
                <View style={[styles.kamparWrapper, styles.wrapperSpaceBlock]}>
                <Text style={[styles.kampar, styles.home1Typo]}>{selectedDate && DateTime.fromFormat(selectedDate, "yyyy-MM-dd").setLocale(language).toFormat('dd/MM/yyyy, EEE').toUpperCase()}</Text>
                </View>
                </View>
                <View style={styles.larWrapperSpaceBlock}>
                <Text style={styles.kampar1}></Text>
                </View>
                </Button>;
            }}
            isOpen={isShowingCalendarPopOver} 
            onClose={() => setIsShowingCalendarPopOver(!isShowingCalendarPopOver)}
            >
            <Popover.Content accessibilityLabel={LocalizedString.chooseDate} w="300">
            <Popover.Arrow />
            <Popover.CloseButton onPress={() => setIsShowingCalendarPopOver(false)}  />
            <Popover.Header>{LocalizedString.chooseDate}</Popover.Header>
            <Popover.Body style={styles.searchBarPopover}>
                <ScrollView>
                    <CalendarView dispatch={dispatch} calendarPressed={calendarPressed} selectedDate={selectedDate} />
                </ScrollView>
            </Popover.Body>
            
            </Popover.Content>
            </Popover>
            </View>
            
            <TouchableOpacity
            style={searchButtonStyle}
            onPress={searchButtonPressed}
            >                
            <Text style={[styles.confirm, styles.bannerTypo]}>{LocalizedString.search}</Text>
            </TouchableOpacity>
            </View>  
            </View>
            );
    }

    };
            
            
const styles = StyleSheet.create({
    horizontal:{
        flexDirection: "row",
        backgroundColor: "white",
        padding: 10,
        alignItems: "center",
    },
    horizontal1:{
        flexDirection: "column",
        padding: 10,
        alignSelf:"center",
        alignItems: "center",
    },
    gap:{
        marginBottom: 160,
    },
    titleText:{
        fontSize: 48,
        color: Color.white,
        backgroundColor:"black",
        width: 800,
        textAlign:"center",
        paddingTop:20,
        fontFamily:FontFamily.interBold,     
    },
    subtitleText:{
        fontSize: 24,
        color: Color.white,
        backgroundColor:"black",
        width: 800,
        textAlign:"center",
        padding:20,
        fontFamily:FontFamily.interBold,     
    },
    disabled: {
        opacity: 0.65,
    },         
    iconLayout: {
        width: 24,
        height: 24,
    },   
    detailsSpaceBlock: {
        paddingHorizontal: Padding.p_5xs,
        paddingVertical: 0,
    },
    fromTypo: {
        color: Color.gray555,
        lineHeight: 16,
        fontSize: FontSize.size_xs,
        textAlign: "left",
    },
    detailsWrapperBorder: {
        marginTop: -12,
        borderWidth: 1,
        borderColor: "#e6e8e7",
        borderStyle: "solid",
        borderRadius: Border.br_5xs,
        paddingHorizontal: Padding.p_5xs,
        zIndex: 0,
    },
    wrapperSpaceBlock: {
        marginLeft: 12,
        flexDirection: "row",
    },
    home1Typo: {
        fontFamily: FontFamily.interBold,
        fontWeight: "600",
    },
    larWrapperSpaceBlock: {
        paddingRight: Padding.p_13xl,
        paddingLeft: Padding.p_21xl,
        borderRadius: Border.br_13xl,
        flexDirection: "row",
    },            
    buttonSearchBg: {
        backgroundColor: Color.steelblue_200,
        flexDirection: "row",
        height: 60,
    },
    bannerTypo: {
        fontSize: FontSize.size_lg,
        lineHeight: 24,
    },                                                                      
    header: {
        paddingHorizontal: Padding.p_17xl,
        paddingVertical: 0,
        flexDirection: "row",
    },            
    from: {
        textAlign: "left",
        fontFamily: FontFamily.interRegular,
    },
    fromWrapper: {
        paddingVertical: Padding.p_9xs,
        backgroundColor: Color.white,
        borderRadius: Border.br_5xs,
        paddingHorizontal: Padding.p_5xs,
        flexDirection: "row",
    },
    fromFrame: {
        zIndex: 1,
    },
    at21: {
        width: 44,
        height: 43,
    },
    ar21Container: {
        marginLeft: 5,
        marginRight: 5,
    },
    ar21: {
        width: 16,
        height: 16,
    },
    kampar: {
        fontSize: FontSize.size_base,
        lineHeight: 24,
        textAlign: "left",
        color: Color.black,
    },
    kamparWrapper: {
        flex: 1,
    },
    details: {
        alignSelf: "stretch",
        flexDirection: "row",
        alignItems: "center",
    },
    kampar1: {
        fontSize: FontSize.size_3xs,
        color: Color.gray999,
        width: 208,
        textAlign: "left",
        fontFamily: FontFamily.interRegular,
    },
    containerInputFrom: {
        paddingTop: Padding.p_3xs,
        paddingBottom: Padding.p_xs,
        backgroundColor: Color.white,
    },
    at221: {
        margin: 7,
        width: 28,
        height: 28,
    },
    at22: {
        width: 33,
        height: 38,
    },
    arrow: {
        borderRadius: 40,
        padding: Padding.p_5xs,
        borderWidth: 1,
        borderColor: "#e6e8e7",
        borderStyle: "solid",
        marginLeft: 12,
        backgroundColor: Color.white,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    details1: {
        alignSelf: "stretch",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
    },
    larWrapper: {
        justifyContent: "center",
        alignItems: "center",
    },
    containerTo: {
        marginTop: 8,
        marginRight: 8,
    },                
    wrapper: {
        width: 78,
    },              
    detailsWrapper: {
        width: 297,
        paddingVertical: Padding.p_3xs,
    },
    confirm: {
        width: 120,
        color: Color.white,
        textAlign: "center",
        fontFamily: FontFamily.interMedium,
        fontWeight: "500",
    },
    buttonSearch: {
        width: 296,
        paddingHorizontal: Padding.p_85xl,
        marginTop: 8,
        borderRadius: Border.br_5xs,
        backgroundColor: Color.steelblue_200,
        justifyContent: "center",
        paddingVertical: Padding.p_xs,
        alignItems: "center",
    },
    searchBarPopover: {
        height: 300,
    }                                                                      
});

export default DesktopSearchBar;