import * as React from "react";
import { useSelector } from 'react-redux';
import { Text, StyleSheet, View, Image,TouchableOpacity } from "react-native";
import { FontSize, Color, FontFamily, Border, Padding, LocalizedString } from "@common";
import {DateTime} from 'luxon';


const TripComponent = ({trip,fromSelectedStation,toSelectedStation
  , handleItemPress,selectedDate}) => {

    const language = useSelector((state) => state.config.language);

  return (
    <View styles={styles.parentSummary}>
      <View style={styles.containerSummary}>
        <View style={styles.containerFromTo}>
          <Text style={styles.title}>{trip && trip.brandName}</Text>
          <Text style={[styles.standard, styles.standardSpaceBlock]}>{trip && trip.coachClass}</Text>
        </View>
        <View style={styles.containerFromTo}>
          <Text style={[styles.seatsAvailable, styles.standardSpaceBlock]}>{trip && trip.vac} {LocalizedString.seatsAvailable}</Text>
        </View>
        {/* <View style={styles.containerFromTo}>
          <View style={styles.frameParent}>
            <View style={styles.fromWrapper}>
              <Text style={styles.from}>{LocalizedString.fromC}</Text>
            </View>
            <Image
              style={styles.frameItem}
              resizeMode="cover"
              source={require("../assets/group-45.png")}
            />
            <View style={styles.toWrapper}>
              <Text style={styles.to}>{LocalizedString.toC}</Text>
            </View>
          </View>
          <View style={styles.kprKamparParent}>
            <View style={styles.kprKampar}>
              <Text style={styles.kpr}>{fromSelectedStation.stationCode}{`\n`}</Text>
              <Text style={styles.kampar}>{fromSelectedStation.stationName}</Text>
            </View>
            <View style={styles.jbLarkinLarContainer}>
            <Text style={styles.kpr}>{toSelectedStation.stationCode}{`\n`}</Text>
              <Text style={styles.kampar}>{toSelectedStation.stationName}</Text>                
            </View>
          </View>
        </View> */}

        <View style={styles.frameGroup}>
          <View style={styles.containerFromTo}>
            {selectedDate && handleItemPress == null && <Text style={styles.time}>{selectedDate && DateTime.fromFormat(selectedDate, "yyyy-MM-dd").setLocale(language).toFormat('dd/MM/yyyy, EEE').toUpperCase()}</Text>}
            <Text style={styles.time}>{trip && trip.time}</Text>          
          </View>
          {handleItemPress == null ? undefined :  
          <View style={styles.containerFromTo}>
                <Text style={[styles.fare]}>{trip && trip.currency} {trip && trip.fare.toFixed(2)}</Text>
                <TouchableOpacity
                  style={styles.buttonBig}
                  onPress={() => handleItemPress(trip)}
                >
                  <Text style={[styles.confirmTypo]}>{LocalizedString.select}</Text>
                </TouchableOpacity>
          </View>}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  frameGroup: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginHorizontal: 32,
    marginTop: 21,
  },
  containerFromTo: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginHorizontal: 32,
    marginTop: 21,
  },
  standard: {
    fontSize: FontSize.size_sm,
    color: Color.black,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    marginTop: 4,
  },
  seatsAvailable: {
    fontSize: FontSize.size_sm,
    fontWeight: "300",
    fontFamily: FontFamily.interMedium,
    width: 105,
  }, 
  buttonBig: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    width: 145,
    paddingVertical: Padding.p_5xs,
    marginTop: 16,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  time:{
    textAlign: "center",
    fontFamily: FontFamily.interBold,
    fontSize: FontSize.size_lg
  },
  fare:{
    width: 145,
    textAlign: "right",
    fontFamily: FontFamily.interBold,
    fontSize: FontSize.size_lg
  },
    icon: {
      position: "relative",
      width: 31,
      height: 9,
    },
    standardSpaceBlock: {
      marginTop: 4,
      textAlign: "left",
    },
    frameChild: {
      position: "relative",
      width: 18,
      height: 18,
      marginLeft: 3.07,
    },
    leftArrowIcon: {
      position: "relative",
      width: 24,
      height: 24,
      overflow: "hidden",
    },
    leftArrow: {
      flexDirection: "row",
      padding: Padding.p_9xs,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    bookFlight: {
      position: "relative",
      fontSize: FontSize.size_xl,
      lineHeight: 32,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
      color: Color.black,
      textAlign: "center",
      width: 192,
    },
    header: {
      flexDirection: "row",
      paddingHorizontal: Padding.p_17xl,
      paddingVertical: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    menuIcon: {
      position: "relative",
      width: 24,
      height: 24,
      overflow: "hidden",
      display: "none",
    },
    hamburgerMenu: {
      flexDirection: "row",
      padding: Padding.p_9xs,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    content: {
      flexDirection: "row",
      paddingHorizontal: Padding.p_base,
      paddingVertical: Padding.p_xs,
      alignItems: "center",
      justifyContent: "center",
    },
    topNavBar: {
      alignSelf: "stretch",
      backgroundColor: Color.gray_100,
      overflow: "hidden",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    title: {
      position: "relative",
      fontSize: FontSize.size_xl,
      lineHeight: 24,
      fontWeight: "700",
      fontFamily: FontFamily.interBold,
      color: Color.steelblue_100,
      textAlign: "left",
    },
    from: {
      position: "relative",
      fontSize: FontSize.size_base,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
      color: Color.black,
      textAlign: "left",
    },
    fromWrapper: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    frameItem: {
      position: "relative",
      width: 193,
      height: 38,
      marginLeft: 14,
    },
    to: {
      position: "relative",
      fontSize: FontSize.size_base,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
      color: Color.black,
      textAlign: "right",
    },
    toWrapper: {
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      marginLeft: 14,
    },
   
    frameParent: {
      width: 296,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    kpr: {
      fontSize: FontSize.size_base,
      fontWeight: "600",
      fontFamily: FontFamily.interBold,
    },
    kampar: {
      fontSize: FontSize.size_xs,
      fontWeight: "300",
      fontFamily: FontFamily.interLight,
    },
    kprKampar: {
      position: "relative",
      color: Color.dimgray_200,
      textAlign: "left",
      width: 97,
    },
    jbLarkinLarContainer: {
      position: "relative",
      color: Color.dimgray_200,
      textAlign: "right",
      width: 105,
      marginLeft: 94,
    },
    kprKamparParent: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 12,
    },
  
    date: {
      position: "relative",
      fontSize: FontSize.size_xs,
      lineHeight: 16,
      fontWeight: "300",
      fontFamily: FontFamily.interLight,
      color: Color.gray555,
      textAlign: "left",
    },
    dateWrapper: {
      borderRadius: Border.br_13xl,
      backgroundColor: Color.white,
      flexDirection: "row",
      paddingHorizontal: Padding.p_5xs,
      paddingVertical: 0,
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    calenderIcon: {
      position: "relative",
      width: 20,
      height: 20,
      overflow: "hidden",
    },
  
    confirmTypo: {
      textAlign: "center",
      fontFamily: FontFamily.interMedium,
      fontWeight: "500",
      fontSize: FontSize.size_lg,
      color: Color.white,
      lineHeight: 24,
    },
    text: {
      position: "relative",
      fontSize: FontSize.size_sm,
      lineHeight: 24,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
      color: Color.black,
      textAlign: "left",    
    },
    wrapper: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginLeft: 10,
    },
    calenderParent: {
      flexDirection: "row",
      paddingLeft: Padding.p_5xs,
      paddingTop: Padding.p_9xs,
      paddingRight: Padding.p_9xs,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    frameView: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    frameInner: {
      position: "relative",
      borderRadius: Border.br_5xs,
      borderStyle: "solid",
      borderColor: "#e6e8e7",
      borderWidth: 1,
      width: 140,
      height: 46,
      marginTop: -36,
    },
    frameContainer: {
      width: 140,
      height: 54,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    frameParent1: {
      width: 140,
      height: 54,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      marginLeft: 16,
    },
    titleSummary: {
      borderWidth: 1,
      borderColor: "rgba(89, 27, 27, 0.05)",
      borderRadius: Border.br_base,
    },
    containerSummary: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      borderRadius: Border.br_base,
      backgroundColor: Color.white,
      shadowColor: "rgba(89, 27, 27, 0.05)",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowRadius: 10,
      elevation: 10,
      shadowOpacity: 1,
      //paddingTop: Padding.p_base,
      paddingBottom: Padding.p_5xl,
    },
    parentSummary: {
      borderRadius: Border.br_base,
      backgroundColor: Color.white,
      shadowColor: "rgba(89, 27, 27, 0.05)",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowRadius: 10,
      elevation: 10,
      shadowOpacity: 1,
      paddingTop: Padding.p_base,
      paddingBottom: Padding.p_5xl,
      marginBottom: 10,
    },
    confirm: {
      position: "relative",
      fontSize: FontSize.size_lg,
      lineHeight: 24,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
      color: Color.secondary,
      textAlign: "center",
      width: 88,
    },
    buttonSmall: {
      borderRadius: Border.br_5xs,
      borderStyle: "solid",
      borderColor: "#ec441e",
      borderWidth: 1,
      width: 156,
      flexDirection: "row",
      paddingHorizontal: Padding.p_85xl,
      paddingVertical: Padding.p_base,
      alignItems: "center",
      justifyContent: "center",
    },
    confirm1: {
      position: "relative",
      fontSize: FontSize.size_lg,
      lineHeight: 24,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
      color: Color.white,
      textAlign: "center",
      width: 88,
    },
    buttonSmall1: {
      borderRadius: Border.br_5xs,
      backgroundColor: Color.steelblue_200,
      width: 156,
      flexDirection: "row",
      paddingHorizontal: Padding.p_85xl,
      paddingVertical: Padding.p_base,
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 16,
    },
    containerButton: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 14,
    },
    container: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    tripDetails: {
      position: "relative",
      backgroundColor: Color.gray_100,
      flex: 1,
      width: "100%",
      height: 800,
      overflow: "hidden",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    tripTitle: {
      padding: 10,
      fontFamily: FontFamily.interBold,
      fontSize: FontSize.size_base
    }
});

export default TripComponent;
