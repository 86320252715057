const DEBUG_MODE = true;
const BASE_URL = "http://test.myetrade.com.my/cts-kampar/api/";
//const BASE_URL = "http://test.kamparputrasentral.my/cts-kampar/api/";
const PAYMENT_GATEWAY_URL = "http://test.myetrade.com.my/cts-kampar/";
//const PAYMENT_GATEWAY_URL = "http://test.kamparputrasentral.my/cts-kampar/";
const PDF_URL = "http://test.myetrade.com.my/cts-kampar/consumer/download-boarding";
//const PDF_URL = "http://test.kamparputrasentral.my/cts-kampar/consumer/download-boarding";
// const BASE_URL = "/cts-kampar/api/";
const MYE_TOKEN = "bXlldHJhZGVAeHlyZW9uIQ==";
const MYE_CLIENT = "xyr888%";
//const CLIENT_ID = "TK/MOBILE";
const CLIENT_ID = "TK/INET";
const MAX_PAX = 6;
const GUEST_ID = 'GUEST';

const WEBURL = "http://kamparputrasentral.my/"
const DUMMY = false;
const TERMS_AND_CONDITIONS_URL = WEBURL + "terms_and_conditions"
const PRIVACY_POLICY_URL = WEBURL + "privacy_policy"
const POLICY_URL = WEBURL + "ticketing_policy"
const DEFAULT_FROM_DESTINATION = {stationCode: 'KPR', stationName: 'Kampar', region: 'Northen'};
export {
  DEBUG_MODE,
  BASE_URL,
  PAYMENT_GATEWAY_URL,
  PDF_URL,
  MYE_TOKEN,
  MYE_CLIENT,
  CLIENT_ID,  
  MAX_PAX,
  TERMS_AND_CONDITIONS_URL,
  PRIVACY_POLICY_URL,
  POLICY_URL,
  DEFAULT_FROM_DESTINATION,
  DUMMY,
  GUEST_ID
};
